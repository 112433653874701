import { Tweet } from "@lgastler-ba/ui";
import React, { useEffect, useState } from "react";
import { Trends } from "@lgastler-ba/twitter";

export default function TweetPage({ id }: { id: string }) {
  const [tweet, setTweet] = useState(undefined);
  const [trends, setTrends] = useState<Trends | undefined>(undefined);

  useEffect(() => {
    fetch(`${process.env.GATSBY_TWEET_API_ENDPOINT}/tweet/${id}`, {
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.GATSBY_TWEET_SECRET ?? "",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setTweet(data);
      });
    fetch(`${process.env.GATSBY_TWEET_API_ENDPOINT}/trends`, {
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.GATSBY_TWEET_SECRET ?? "",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setTrends(data);
      });
  }, []);

  return (
    <Tweet
      tweet={tweet}
      trends={trends?.trends.map((trend) => ({
        id: `${trend.name}_${trend.query}`,
        title: trend.name,
        subtitle: trends.locations[0].name,
        amount: trend.tweet_volume ? `${trend.tweet_volume} tweets` : undefined,
        url: trend.url,
      }))}
    />
  );
}
